import React from 'react';
import './Header.css';
import headLogo from "../images/logoHead.png";
import twitter from "../images/twitter.png";
import chart from "../images/chart.png";
import tg from "../images/tg.png";

const Header = () => {
  return (
    <header className="header">
      <div className="logo">
        <img src={headLogo} alt="head-logo" />
      </div>
      <div className="menu">
        <a href="https://x.com/mastercat21ad" target='_blank' rel="noopener noreferrer">
          <img src={twitter} alt="Twitter" />
        </a>
        <a href="https://t.me/sherlockwhiskers" target='_blank' rel="noopener noreferrer">
          <img src={tg} alt="Telegram" />
        </a>
        <a href="https://birdeye.so/" target='_blank' rel="noopener noreferrer">
          <img src={chart} alt="Chart" />
        </a>
      </div>
    </header>
  );
};

export default Header;

